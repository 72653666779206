<template>
    <div>
        <ts-page-title
            :title="$t('requestForDelivery.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('requestForDelivery.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <a-tabs v-model="activeKey"
									@change="tapChange"
								>
                    <a-tab-pane :key="1" :tab="$t('requestForDelivery.nearByDriver')" force-render>
											<RequestNew ref="request_new"/>
										</a-tab-pane>
                    <a-tab-pane :key="2" :tab="$t('requestForDelivery.requestedList')">
											<RequestList ref="request_list"/>
										</a-tab-pane>
                </a-tabs>
            </ts-panel-wrapper>
        </ts-panel>
    </div>
</template>

<script>
import RequestNew from './request-new.vue'
import RequestList from './requested-list.vue'
export default {
    name: "requestForDelivery",
		components: {
			RequestNew,
			RequestList
		},
    data() {
        return {
            activeKey: 1
        };
    },
		methods: {
			tapChange(index){
				if(index == 1)
				{
					this.$refs.request_new.fetchViewData()
				}else {
					this.$refs.request_list.fetchData()
				}
			}
		},
		beforeRouteEnter (to, from, next) {
			next(vm => {
				vm.$refs.request_new.fetchViewData()
			})
		}

};
</script>
