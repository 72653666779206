<template>
    <div>
        <ts-panel>
            <ts-panel-wrapper>
                <ts-loading-banner :loading="loading">
                    <div class="tw-flex tw-justify-between tw-items-center">
                        <div class="tw-flex tw-space-x-3">
                            <div v-if="model.driver_id_list.length" class="tw-flex tw-space-x-3">
                                <a-select
                                    v-model="model.agency_id"
                                    :options="agency"
                                    style="width:200px"
                                ></a-select>
                                <a-button
                                    type="primary"
                                    @click.prevent="request"
                                >
                                    {{ $t("requestForDelivery.request") }}
                                    <i class="fas fa-bullhorn tw-ml-3"></i>
                                </a-button>
                            </div>
                            <a-select
                                v-model="vehicle_type_id"
                                :options="vehicleTypeList"
                                style="width:200px"
                                v-if="!model.driver_id_list.length"
                            ></a-select>
                            <a-button
                                type="primary"
                                @click.prevent="getNearBy"
                                v-if="!model.driver_id_list.length"
                            >
                                <i class="fas fa-search-location tw-mr-3"></i>
                                {{ $t("requestForDelivery.nearByDriver") }}
                            </a-button>
                        </div>
                    </div>
                    <div style="min-height: 400px">
                        <table class="tw-w-full tw-mt-3">
                            <thead>
                                <tr>
                                    <th
                                        class="tw-bg-gray-200 tw-py-1 tw-px-2 tw-w-8"
                                    >
                                        <ts-checkbox
                                            @change="checkboxChange"
                                            :checkedValue="isChecked"
                                        ></ts-checkbox>
                                    </th>
                                    <th
                                        class="tw-uppercase tw-bg-gray-200 tw-py-1 tw-px-2"
                                    >
                                        {{
                                            $t("requestForDelivery.driverCode")
                                        }}
                                    </th>
                                    <th
                                        class="tw-uppercase tw-bg-gray-200 tw-py-1 tw-px-2"
                                    >
                                        {{
                                            $t("requestForDelivery.driverName")
                                        }}
                                    </th>
                                    <th
                                        class="tw-uppercase tw-bg-gray-200 tw-py-1 tw-px-2"
                                    >
                                        {{
                                            $t(
                                                "requestForDelivery.driverStatus"
                                            )
                                        }}
                                    </th>
                                    <th
                                        class="tw-uppercase tw-bg-gray-200 tw-py-1 tw-px-2"
                                    >
                                        {{
                                            $t("requestForDelivery.driverPhone")
                                        }}
                                    </th>
                                    <th
                                        class="tw-uppercase tw-bg-gray-200 tw-py-1 tw-px-2"
                                    >
                                        {{ $t("requestForDelivery.distance") }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(d, index) in drivers" :key="index">
                                    <td class="tw-py-1 tw-px-2 tw-w-8">
                                        <ts-checkbox
                                            v-model="model.driver_id_list"
                                            :value="d.driver_id"
                                        ></ts-checkbox>
                                    </td>
                                    <td class="tw-py-1 tw-px-2">
                                        {{ d.driver_code }}
                                    </td>
                                    <td class="tw-py-1 tw-px-2">
                                        {{ d.driver_name }}
                                    </td>
                                    <td class="tw-py-1 tw-px-2">
                                        {{ d.driver_status }}
                                    </td>
                                    <td class="tw-py-1 tw-px-2">
                                        {{ d.phone1 + " / " + d.phone2 }}
                                    </td>
                                    <td>
                                        {{
                                            round(d.distance / 1000, 2) +
                                                " " +
                                                $t("requestForDelivery.km")
                                        }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </ts-loading-banner>
            </ts-panel-wrapper>
        </ts-panel>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { round } from "lodash";
export default {
    name: "requestForDelivery",
    data() {
        return {
            loading: false,
            vehicle_type_id: 1,
            drivers: [],
            model: {
                driver_id_list: [],
								agency_id: null
            }
        };
    },
    computed: {
        ...mapState("agency/requestForDelivery", ["formModels"]),
        agency() {
            if (!this.formModels.agency) return [];

            return this.formModels.agency.map(el => ({
                value: el.agency_id,
                label: el.agency_name
            }));
        },
        vehicleTypeList() {
            if (!this.formModels.vehicleType) return [];

            return this.formModels.vehicleType.map(el => ({
                value: el.vehicle_type_id,
                label: el.vehicle_types
            }));
        },
        isChecked() {
            if (!this.drivers.length) return false;
            return this.model.driver_id_list.length == this.drivers.length;
        }
    },
    methods: {
        ...mapActions("agency/requestForDelivery", [
            "getFormViewData",
            "nearByDrive",
            "requestDelivery"
        ]),
        round,
        async fetchViewData() {
            this.loading = true;
            await this.getFormViewData({ param: {}, index: undefined });
            this.loading = false;
        },
        getNearBy() {
            this.loading = true;
            this.nearByDrive({
                vehicle_type_id: this.vehicle_type_id
            })
                .then(response => {
                    this.drivers = response.data;
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        request() {
            this.loading = true;
            this.requestDelivery(this.model)
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        checkboxChange(e) {
            if (e) {
                this.drivers.forEach(el => {
                    this.model.driver_id_list.push(el.driver_id);
                });
            } else {
                this.model.driver_id_list = [];
            }
        }
    }
};
</script>
